import React from "react";
import "./heroArea.css";

function HeroArea() {
  return (
    <div className="heroArea">
      <LeftArea />
      <RightArea />
    </div>
  );
}

export default HeroArea;

function LeftArea() {
  const subHeader = "Ashley Bunch";
  const subDescription = "Streetstyle Contemporary Artist";
  return (
    <div className="leftAreaRoot">
      <div className="leftAreaSubContainer">
        <div className="leftAreaSubHeader">{subHeader}</div>
        <div className="leftAreaSubDescription">{subDescription}</div>
      </div>
    </div>
  );
}

function RightArea() {
  return (
    <div className="rightAreaRoot">
      <div className="rightAreaContainer">
        <img
          className="rightAreaImage"
          src="RadChicken.jpeg"
          alt="Rad Chicken"
        />
      </div>
    </div>
  );
}
